import React from 'react'
import './PolicyPage.scss'
const PolicyPage = () => {
  return (
    <div className='design'>
      <h1 className='match'>Privacy Policy for Skill Match AI</h1><br/>
      <p><strong>Effective Date:</strong> 28th November, 2024</p>
      <p><strong>Last Updated  :</strong> 29th November, 2024</p>
      <div className='policybody'>
      <p>
        At <strong>Verisult Training and Consulting Inc.</strong>, we value your privacy and are committed to protecting your personal
        information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our <strong>Skill Match AI</strong> platform.
      </p>

      <h2>1. Information We Collect</h2>
      <p className='paragraph'>We may collect the following types of information:</p>
      <ul className= 'list'>
        <li><strong>Personal Information:</strong> Your name, email address, phone number, and any other details provided during registration.</li>
        <li><strong>Resume Data:</strong> Information extracted from resumes you upload, including but not limited to work history, education, and skills.</li>
        <li><strong>Job Preferences:</strong> Your job preferences, including location, desired role, and other criteria.</li>
        <li><strong>Usage Data:</strong> Information about how you use our app, including device type, IP address, and app interaction logs.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p className='paragraph'>We use the information collected to:</p>
      <ul className='list'>
        <li>Match your resume and preferences with job openings.</li>
        <li>Improve our app's functionality and user experience.</li>
        <li>Communicate with you about job recommendations and updates.</li>
        <li>Ensure compliance with legal and regulatory requirements.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p className='paragraph'>We do not sell or share your personal information with third parties, except as necessary to:</p>
      <ul className='list'>
        <li>Connect you with job opportunities through our trusted partners.</li>
        <li>Comply with legal obligations.</li>
        <li>Protect the rights, safety, and security of <strong>Skill Match AI</strong>, its users, and the public.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p className='paragraph'>
        We implement robust security measures to protect your information. However, no method of transmission over the
        internet is 100% secure. While we strive to protect your data, we cannot guarantee its absolute security.
      </p>

      <h2>5. Your Rights</h2>
      <p className='paragraph'>You have the right to:</p>
      <ul className='list'>
        <li>Access, update, or delete your personal information.</li>
        <li>Withdraw your consent for data processing.</li>
        <li>Lodge a complaint with a data protection authority if you believe your rights have been violated.</li>
      </ul>

      <h2>6. Third-Party Services</h2>
      <p className='paragraph'>
        Our app may link to third-party services or job boards. We are not responsible for the privacy practices of
        these external sites. We encourage you to review their privacy policies.
      </p>

      <h2>7. Cookies</h2>
      <p className='paragraph'>
        We use cookies to enhance your app experience. You can manage or disable cookies through your device settings,
        but doing so may affect the app's functionality.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p className='paragraph'>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated <strong>“Effective Date”</strong>.
      </p>

      <h2>9. Contact Us</h2>
      <p className='paragraph'>If you have questions or concerns about this Privacy Policy, please contact us at:</p>
      <ul className='list'>
        <li><strong>Email:</strong> <a href="mailto:info@verisult.com">info@verisult.com</a></li>
      </ul>
      </div>
      <br/><br/>

      <footer className='right'>
      <p>&copy; 2024 Verisult. All rights reserved.</p>
      </footer>
    </div>
  )
}

export default PolicyPage