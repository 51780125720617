import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/Logo.png';
import landingImage from '../../assets/verisult.png';
import './LandingPage.scss';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    console.log('Login button clicked');
  };

  const handleSignUpClick = () => {
    console.log('Sign Up button clicked');
  };
  
  const goToUploadPage = () => {
    navigate('/upload');
  };

  return (
    <div className="landing-page">

      <div className="navbar">
        <div className="logo">
          <img src={Logo} alt="logo"/>
        </div>
        <div className="navbar-buttons">
          <button className="login-button" onClick={handleLoginClick}>Login</button>
          <button className="signup-button" onClick={handleSignUpClick}>Sign Up</button>
          <div className='tooltip'>Coming Soon!</div>
        </div>
      </div>

      <div className="page-content">
        <div className="header">
          <h2>Find Your Dream Career.</h2>
          <h3>Simple. Innovative. Precise.</h3>
          <p>
          Experience a smarter way to explore careers through advanced AI models that align your skills and aspirations with the best opportunities.

          </p>
          <button className="try-button" onClick={goToUploadPage}>Try Now</button>
        </div>
        <div className="landingImage">
          <img src={landingImage} alt="landingImage"/>
        </div>

      </div>

    </div>
  );
};

export default LandingPage;