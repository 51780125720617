import React from "react";
import "./NextSteps.scss";

const NextSteps = () => (
  <div className="next-steps">
    <h3>Next Steps</h3>
    <p>
      Upskill in this field through skill development, career mentorship,
      coaching, and live projects. We are rooting for you.
    </p>
  </div>
);


export default NextSteps