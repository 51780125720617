import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import React from 'react';
import ReactDOM from 'react-dom/client';
import queryClient from './api/queryClient';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const sessionStoragePersister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister: sessionStoragePersister }}
  >
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </PersistQueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
