import React from "react";
import { TailSpin } from 'react-loader-spinner';
import AvailableRole from "./AvailableRole";
import "./AvailableRoles.scss";

const AvailableRoles = ({ roles }) => {
  if (!roles) {
    return <div className="spinner-container">
      <TailSpin height="30" width="30" color="#000000" />
    </div>
  }

  return (
    <div className="available-roles">
      <table>
        <thead>
          <tr>
            <th>Available Roles</th>
            <th>Compatibility (%)</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(roles) && roles.length > 0 ? (
          roles.map((role, index) => (
            <AvailableRole
              key={index}
              link={role.job_link}
              role={role.job_description}
              compatibility={role.match_percentage}
            />
          ))
        ) : (
          <tr>
            <td colSpan="2">No roles available</td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  );
};

export default AvailableRoles;