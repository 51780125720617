import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { availableRoles, sessionResults } from '../../api/apiService';
import AvailableRoles from '../../components/AvailableRoles';
import JobCategory from '../../components/JobCategory';
import NextSteps from '../../components/NextSteps';
import './ResultsPage.scss';

const ResultsPage = () => {
  const navigate = useNavigate();
  const hasMutatedRef = useRef(false);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const session_id = searchParams.get("session_id");

  const mutation = useMutation({
    mutationFn: sessionResults,
    onSuccess: (data) => {
      queryClient.setQueryData(["categories"], data?.categories);
      queryClient.setQueryData(["task_id"], data?.task_id);
      queryClient.setQueryData(["session"], data?.session);
    },
    onError: (error) => {
      console.error('Session fetching error:', error);
      navigate("/");
    },
    retry: false
  });

  const { data: categories, error } = useQuery({
    queryKey: ["categories"],
    retry: false,
  });

  const { data: task_id } = useQuery({
    queryKey: ["task_id"]
  });
  
  const {data: roles } = useQuery({
    queryKey: ["roles", task_id],
    queryFn: () => availableRoles(task_id),
    enabled: !!task_id,
    refetchInterval: (data) => {
      const response = data?.state?.data
      if (response?.status === "completed" || response?.status === "failed") {
        return false
      }
      return 2000;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!hasMutatedRef.current) {
      if (session_id) {
        mutation.mutate(session_id);
      }
      hasMutatedRef.current = true;
    } else if (error instanceof Error && !session_id) {
      navigate("/upload");
    }
  }, [session_id, mutation, navigate, error]);

  const handleOnclick = () => {
    window.open("https://verisult.com/service/training/cap/", "_blank");
  }

  return (
    <>
      <div className='app'>
        <div className='job-categories'>
          {categories && categories.length > 0 ? (
            categories.map((item, index) => (
              <JobCategory
                key={index}
                label={item.job_category}
                value={item.match_percentage}
              />
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
        <div className="next-steps-section" onClick={handleOnclick}>
          <NextSteps />
        </div>
      </div>
      <div className='available-roles-section'>
        <div className="roles-section">
          <AvailableRoles roles={roles?.result} />
        </div>
      </div>
    </>
  );
};

export default ResultsPage;