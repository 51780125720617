import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
} from 'chart.js';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import './JobCategory.scss';
  
ChartJS.register(ArcElement, Tooltip, Legend);

const JobCategory = ({ value, label }) => {
  const chartData = useMemo(() => ({
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: ["#F55D0B", "#453d3d"],
        borderWidth: 0,
      },
    ],
  }), [value]);

  const chartOptions = useMemo(() => ({
    cutout: '93%',
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: { enabled: false },
    },
  }), []);

  return (
    <div className='job-category'>
      <Doughnut data={chartData} options={chartOptions} width={200} height={200} />
      <div className='job-category-match'>
        {value}%
      </div>
      <p className='job-category-label'>{label}</p>
    </div>
  );
};


export default JobCategory;