import api from './api';

export const uploadResume = async (formData) => {
  try {
    const response = await api.post('/resume/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const jobCategories = async (formData) => {
  try {
    const response = await api.post('/prompt/job-categories', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching job categories:", error);
    throw error;
  }
}

export const availableRoles = async (task_id) => {
  try {
    const response = await api.get(`/jobs/${task_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching available roles:", error);
    throw error;
  }
}

export const sessionResults = async (session_id) => {
  try {
    const response = await api.get(`/session/${session_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching session:", error);
    throw error;
  }
}