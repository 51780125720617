import React from "react";

const AvailableRole = ({ role, link, compatibility }) => {
  return (
    <tr className="role-row">
      <td className="role-name">
        <span className="bullet">•</span>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {role}
        </a>
      </td>
      <td className="role-compatibility">{compatibility}%</td>
    </tr>
  );
};

export default AvailableRole;