import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import './Modal.scss';

const Modal = ({ isVisible, text }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-spinner-container">
        <TailSpin height="80" width="80" color="#ffffff" />
        <p className="modal-text">{text}</p>
      </div>
    </div>
  );
};

export default Modal;