import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.scss';
import LandingPage from './pages/LandingPage';
import PolicyPage from './pages/PolicyPage';
import ResultsPage from './pages/ResultsPage';
import SubmissionPage from './pages/SubmissionPage';
import FileUpload from './pages/UploadPage';

function App() {
  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true, }}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/upload" element={<FileUpload />} />
        <Route path="/submission" element={<SubmissionPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/privacy-policy" element={<PolicyPage />} />
      </Routes>
    </Router>
  );
}

export default App;
