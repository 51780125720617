import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { uploadResume } from '../../api/apiService';
import logoImage from '../../assets/Upload.png';
import pdfImage from '../../assets/pdf.png';
import wordImage from '../../assets/word.png';
import ModalComponent from "../../components/Modal";
import './UploadPage.scss';

const UploadPage = () => {
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [image, setImage] = useState(logoImage);
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: uploadResume,
    onSuccess: (data) => {
      queryClient.setQueryData(["resume_text"], data);
      navigate('/submission');
    },
  });

  const updateFile = (input) => {
    setFile(input);

    if (input) {
      if (input.type === "application/pdf") {
        setImage(pdfImage);
      } else if (
        input.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        input.type === "application/msword"
      ) {
        setImage(wordImage);
      } else {
        setImage(logoImage);
      }
    }
  };

  const uploadHandler = (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    updateFile(fileUploaded);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const fileDropped = event.dataTransfer.files[0];
    updateFile(fileDropped);
  };
    
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handlePolicyCheckboxChange = (event) => {
    setIsPolicyChecked(event.target.checked);
  }

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    mutation.mutate(formData);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Handle drag leave
  const handleDragLeave = () => {};

  return (
      <div className='main1'>
        <div className= "main2">
            <p>Upload Resume</p>
        </div>
        <div className='main3'
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}>
            <img src={image} alt="File Upload" className={!file ? "Logo-Image" : "Uploaded-Image"} />
            <div className='main7'>
              <p className="main">Drag & drop files or <span className='browse' onClick={handleBrowseClick}>Browse</span></p>
              <p className="info">Supported formats: PDF, Word</p>
            </div>
        </div>

        <div>
          <input
            type="checkbox"
            id="signup"
            checked={isPolicyChecked}
            onChange={handlePolicyCheckboxChange}/>
          <label 
            htmlFor="signup" className="checkbox-label"> By submitting this form, you agree to our <a href="/privacy-policy" className ="policy-link" target="_blank">[Privacy Policy]</a>
          </label>
        </div>
        <div className="main4">
          <input
            type="file"
            ref={fileInputRef}
            onChange={uploadHandler}
            style={{ visibility: 'hidden' }}
          />
          <button onClick={handleUpload} disabled={!file || !isPolicyChecked}>
            Submit Resume
          </button>
        </div>
        <ModalComponent isVisible={mutation.isPending} text="" />
      </div>
  );
};

export default UploadPage;
