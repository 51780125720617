import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from 'react-router-dom';
import { jobCategories } from '../../api/apiService';
import aar from '../../assets/Star 1.png';
import Modal from "../../components/Modal";
import './SubmissionPage.scss';

const SubmissionPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const quillRef = useRef(null);

  const { data: resume_text, error } = useQuery({
    queryKey: ["resume_text"],
    retry: false,
  });

  const mutation = useMutation({
    mutationFn: jobCategories,
    onSuccess: (data) => {
      queryClient.setQueryData(["categories"], data?.categories);
      queryClient.setQueryData(["task_id"], data?.task_id);
      queryClient.setQueryData(["session"], data?.session);
      navigate('/results');
    },
  });

  useEffect(() => {
    setContent(resume_text);
  }, [resume_text]);

  useEffect(() => {
    if (error instanceof Error) {
      navigate("/upload");
    }
  }, [navigate, error]);
  
  const handleSubmit = async (event) => {
    if (isChecked && email.trim() === "") {
      alert("Please enter your email address.");
      return;
    } else if (isChecked && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())){
      alert("Enter a valid email address.");
      return
    }
    const formData = new FormData();
    formData.append('resume', content);
    formData.append('email', email.trim());
    formData.append('location', document.getElementsByName("location")[0].value);
    
    mutation.mutate(formData);
  };

  const handleChange = (input) => {
    if (input.replace(/<\/?[^>]+(>|$)/g, "").trim() === "")
      return;

    queryClient.setQueryData(["resume_text"], input);
    setContent(input);
  };

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmail(input.trim())
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setEmail("")
  };

  return (
    <div className= "all">
      <div className= "top">
        <p>Parsed Resume</p>
      </div>
      {/* <textarea className="box" placeholder=" " defaultValue={resume_text} name="resume"></textarea> */}
      {/* <div className="box" > */}
      <ReactQuill
        theme="snow"
        ref={quillRef}
        className="box"
        readOnly={false}
        value={content}
        onChange={handleChange}
        modules={{ toolbar: false }}
      />
      {/* </div> */}
      <div className='forward'>
        <div>
          <img src={aar} alt="File Upload" className="star" />
          <label htmlFor="fileSelect" className="select-label"> What country do you want to work in ?</label>
            <select id="fileSelect" className="file-select" name="location">
              <option value=""  disabled hidden>--select an option--</option>
              <option value="Canada">Canada</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Ghana">Ghana</option>
          </select>
        </div>
        <div>
          <input type="checkbox" id="signup" checked={isChecked} onChange={handleCheckboxChange}/>
          <label htmlFor="signup" className="checkbox-label"> Get your result sent directly to your inbox</label>
          <input
            id="input"
            type="text"
            value={email}
            className="file-select"
            onChange={handleEmailChange}
            placeholder='insert your email address'
            style={{ visibility: isChecked ? 'visible' : 'hidden' }}/>
        </div>
      </div>
      <div className="tton">
        <button onClick={handleSubmit} disabled={mutation.isPending}>
        {mutation.isPending ? 'Matching...' : 'Match'}</button>
      </div>
      <Modal isVisible={mutation.isPending} text="" />
    </div>
  );
};

export default SubmissionPage;